import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Ambassadors.css";
import sliderPic1 from "./../../images/1.PNG";
import sliderPic2 from "./../../images/2.PNG";
import sliderPic3 from "./../../images/3.PNG";
import sliderPic4 from "./../../images/4.PNG";
import sliderPic5 from "./../../images/5.PNG";
import axios from "axios"
import linkedin from "./../../images/twitter.png";
import twitter from "./../../images/linkedin.png";
import { useSelector } from "react-redux";
import { getAmbassadors } from "../../redux/CustomerReducer";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import sepAmb from "./../../images/sepAmb.png";
import Slider from "react-slick";
const Ambassadors = () => {

  const[user,setUser]=useState();
  // const dispatch=useDispatch();
  // const ambassadorsData = useSelector(
  //   (state) => state?.CustomerReducer?.getAmbassadorsData
  // );

  const settings = {

    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 3,
    speed: 500,
    responsive: [
      {
        breakpoint: 968,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
          centerMode: false,
       
        }
      },

    ]


  };


// useEffect(()=>{

//  dispatch(getAmbassadors())

// },[])

//const filtered = ambassadorsData?.filter((item) => item.isVerify === true);
let filtered=[{image:sliderPic5,fullName:'Christian Chalfoun',title:'Web3 Community Strategist',linkedIn:'https://www.linkedin.com/in/christian-chalfoun-649207a4/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',twitter:'https://x.com/cryptohead3/status/641045743705284608?s=46'},{image:sliderPic1,fullName:'Anna Tutova',title:'CEO Coinstelegram',linkedIn:'https://www.linkedin.com/in/anna-tutova/',twitter:'https://twitter.com/anna_tutova?t=stNONAGiK7rDfYWxlDhrCw&s=08'},{image:sliderPic2,fullName:'Zoe (Zee) West',title:'Fintech professional',linkedIn:'https://www.linkedin.com/in/thefuturecrowd/',twitter:'https://twitter.com/Zee_West?t=NhVL9urBXHW5fQCAZk68qw&s=09'},{image:sliderPic3,fullName:'Maya Hautefeuille',title:'Global citizen in media',linkedIn:'https://www.linkedin.com/in/maya-hautefeuille/',twitter:'https://twitter.com/mhautefeuille1?t=YqHBipFSHV226BFlYduymw&s=09'},{image:sliderPic4,fullName:'Elias Ahonen',title:'Blockchain Author',linkedIn:'https://www.linkedin.com/in/eliasahonen/',twitter:'https://twitter.com/eahonen?t=LECstxiTj_2S79kQ7g3nSQ&s=09'}];
console.log("filtered",filtered)
  return (
    <>
      <div className="mainContainerAmb">
        <div className="topSliderBox">
          <div className="ourAmbTxt">Our Ambassadors</div>
          <img src={sepAmb} />
          <div className="txtAmbTop">
          We believe our vision to build a top-notch ecosystem can only be achieved by partnerships with key members of the Crypto & Web3 community. So if you are an active participant in the Web3 space, we want to hear from you and would encourage you to Apply to join our industry leaders and help us build the next unicorns of the Crypto & Web3 space!

          </div>
        </div>
<div className="mainSlidersTop">    
    <Slider {...settings}>
          
          
      {filtered?.map((item)=>(
      <div className="sliderCard">
            
              <img  className="sliderImgM"  src={item.image} />

            <div className="topBottomTxtSliderPr">
              <div className="headingSlider">{item.fullName} </div>
              <div className="sliderDec">{item.title}</div>

              <div className="topIconsAmb">
        <a href={item.linkedIn} target="_blank">        <img src={linkedin} /></a>
        <a href={item.twitter} target="_blank">          <img src={twitter} /></a>
              </div>
            </div>
          </div>

      ))   
      
        }
         
          
          
          
        
          
          
        </Slider>

        </div>


   <Link to="/user/join/crypto">     <div className="joinAmbBtn">Join our Ambassadors</div></Link>
      </div>
    </>
  );
};

export default Ambassadors;
