import React from "react";
import { useState } from "react";

import { useDispatch } from "react-redux";
// import { useRouter } from "next/router";
// import './Login.css'
import { useNavigate } from "react-router-dom";
import './LoginAdmin.css'
import { loginAdmin } from "../../../redux/CustomerReducer";

import Swal from "sweetalert2";
// import { adminLogin } from "@/slices/AdminSlice";

// import { loginAdmin } from "@/slices/counterSlice";


// import { increment } from "@/slices/AdminSlice";
// import { decrement } from "@/slices/AdminSlice";
import { useSelector } from "react-redux";
// import type  {RootState} from './../../../Store/store';

const LoginAdmin = () => {
  const dispatch=useDispatch();
 const navigate=useNavigate()
//   const router=useRouter();
const[valid,setValid]=useState();

  const counter=useSelector((state)=>state?.counterReducer?.userData)

  console.log("counter",counter)

  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setUser({
      ...user,
      [name]: value,
    });
  };

  const loginClicked = async () => {
    console.log("Clicked");


    const respone=await dispatch(loginAdmin(user))
    

  console.log("respone",respone,respone?.payload?.data?.status)

  if(respone?.payload?.data?.status)
  {
    navigate('/admin/users')

  }
  else{
    setValid(true)
  }

  };

  return (
    <>
      <div className="mainContainerLogin">
        <div className="userLoginTxt">Admin Login</div>

        <div className="topInputFieldLogin">
          <div className="inputTxtLogin">Email</div>
          <input
            className="inputLogins"
            type="email"
            onChange={handleChange}
            name="email"
            placeholder="Enter Email"
          />
        </div>
        <div className="topInputFieldLogin">
          <div className="inputTxtLogin">Password</div>
          <input
            className="inputLogins"
            onChange={handleChange}
            name="password"
            type="password"
            placeholder="Enter Password"
          />
        </div>
        {/* <div className="forgotPassTxt">Forgot password?</div> */}
{valid&&<div style={{color:"red"}}>Check with your email or password</div>}

        <button className="loginBtn" onClick={() => loginClicked()}>
          Login
        </button>
      </div>
    </>
  );
};

export default LoginAdmin;
