import React from 'react'
import Footer from '../Components/Footer/Footer'
import ForgotPassword from '../Components/ForgotPassword/ForgotPassword'
import ResetPassword from '../Components/ResetPassword/ResetPassword'
import TopBar from '../Components/TopBar/TopBar'

const ResetPasswordPage = () => {
  return (
    <div>
<TopBar/>
<ResetPassword/>
<Footer/>

    </div>
  )
}

export default ResetPasswordPage