import React from 'react'
import './CoomingSoon.css'
const CoomingSoon = () => {
  return (
    <>
    <div className='mainComingSoon'>
        
<div className='comingSoonTxt'>
    Coming Soon 
</div>
    </div>
    </>

  )
}

export default CoomingSoon