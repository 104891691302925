import axios from "axios";
import React, { useState } from "react";
// import "./Login.css";
import { loginCustomer } from "../../redux/CustomerReducer";
import { useNavigate } from "react-router-dom";
import { useParams  } from "react-router-dom";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { SERVERURL } from "../../ServerUrl";
import { resetPassword } from "../../redux/CustomerReducer";
import { useDispatch } from "react-redux";
const ResetPassword = () => {
  const [valid, setValid] = useState();
  const cookies = new Cookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [user, setUser] = useState({
    password: "",
    confirmPassword: "",
  });
  const { id } = useParams();
console.log("dataTempid",id)
  const handleChange = (e) => {
    const { value, name } = e.target;

    setUser({ ...user, [name]: value });
  };

  const loginClicked = async () => {
    if (user.password && user.confirmPassword) {
      if (user.password === user.confirmPassword) {
        const data = {
          password: user.password,
          id:id
        };
      const response=await  dispatch(resetPassword(data));

        console.log("responsePassword",response)
        if(response?.payload?.data?.message)
        {
          navigate('/user/login')
        }
      Swal.fire(
        response?.payload?.data?.message,
            response?.payload?.data1?.message,
            'success'
          )
      } else {
        setValid(3);

      }
    } else {
      setValid(2);
    }
  };

  return (
    <>
      <div className="mainContainerSignup mainContainerLogin">
        <div className="cardSignup">
          <div className="cardSignupInner">
            <div className="topSignupTxt">
              Enter password and confirm password
            </div>

            <div className="topTxtInput">
              <div className="inputLbl">Password</div>
              <input
                placeholder="password"
                className="inputSignup"
                onChange={(e) => handleChange(e)}
                name="password"
                type="password"
              />
            </div>
            <div className="topTxtInput">
              <div className="inputLbl">Confirm Password</div>
              <input
                placeholder="Confirm Password"
                className="inputSignup"
                type="password"
                onChange={(e) => handleChange(e)}
                name="confirmPassword"
              />
            </div>
            {valid === 2 && (
              <div style={{ color: "red" }}>Enter all fields</div>
            )}
            {valid === 3 && (
              <div style={{ color: "red" }}>Passwords are not same</div>
            )}

            <div className="home-content--btn2" onClick={loginClicked}>
              <div className="hover-btn2" onClick={loginClicked}></div>
              <button className="content-btn--inner2" onClick={loginClicked}>
                <p>Submit</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
