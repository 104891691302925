import React, { useState } from 'react'
import './NewsLetter.css'
import Swal from 'sweetalert2'
import axios from "axios";
import emailImg from './../../images/emailBox.png'
import { BiLoaderAlt } from 'react-icons/bi';
import { SERVERURL } from '../../ServerUrl';
const NewsLetter = () => {

  const[email,setEmail]=useState();
  const[vald,setValid]=useState();

  const[loader,setLoader]=useState(0);

  const subscribeClicked=async()=>{

    if(email)
    {

      setLoader(1)
      const data={
        email:email
      }
      const response=await axios.post(
        `${SERVERURL}/subscribe`,
        data
        
        );
        if(response)
        {
          setLoader(0)
        }

        console.log("responseLetter",response?.data?.message)
        Swal.fire(
          response?.data?.message,
          response.data.status,
          'success'
        )
        Swal.fire(
          'Good job!',
          response?.data?.message,
          'success'
        )

    }
    else{
      Swal.fire(
        'Please Enter Email',
        
      )
    }

   

  }

  console.log("email",email)




  
  return (
    <>
    <div className='mainNewLLetter'>
   <div className='topLeftNews'>
   <img src={emailImg}/> 
    <div className='topMiddlNewsTxt'>
    <div className='subKepTxt'>Subscribe & stay updated on the latest Crypto news! </div>
    <div className='newsLetterTxt'>Newsletter</div>

    

    </div>
   </div>
    <div className='topInputBtn'>
      <div>

      {/* <div>Please enter email</div> */}
      <input
        placeholder='Type your Email Address '
        className='inputEmail'
        onChange={(e)=>setEmail(e.target.value)}
        />

      </div>
        
        <div className='btnSubscribe'
        onClick={subscribeClicked}
        >
          {loader===1?
          <BiLoaderAlt className='loaderNewLetter'/>:
            "Subscribe"}
        </div>
    </div>
    </div>
    
    </>
  )
}

export default NewsLetter