import React from 'react'
import Footer from '../Components/Footer/Footer'
import ForgotPassword from '../Components/ForgotPassword/ForgotPassword'
import TopBar from '../Components/TopBar/TopBar'

const ForgotPasswordPage = () => {
  return (
    <>
    
    <TopBar/>
<ForgotPassword/>
    <Footer/>
    </>
  )
}

export default ForgotPasswordPage