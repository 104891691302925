import axios from "axios";
import React, { useState } from "react";
// import "./Login.css";
import { loginCustomer } from "../../redux/CustomerReducer";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../../redux/CustomerReducer";
import Swal from 'sweetalert2'
import Cookies from "universal-cookie";
import { SERVERURL } from "../../ServerUrl";
import { useDispatch } from "react-redux";
const ForgotPassword = () => {
  const[valid,setValid]=useState()
  const cookies = new Cookies();
  const navigate=useNavigate();
  const dispatch=useDispatch();
  const[user,setUser]=useState({
    
    email:"",
    password:"",
  


  })

  const handleChange=(e)=>{
    const{value,name}=e.target;
  
    setUser({...user,[name]:value})
  
  }


  const loginClicked=async()=>{

    if(user.email)
    {
      const data={
        email:user.email
      }
      const response=await dispatch(forgotPassword(data))
      console.log("response",response)

      if(response?.payload?.data?.message)
      {
        Swal.fire(
          response?.payload?.data?.message,
              response?.payload?.data1?.message,
              'success'
            )
      }
      if(response?.payload?.response?.data?.status===false)
      {
        Swal.fire(
          response?.payload?.response?.data?.message,
          "Error",
              'error'
            )
      }
     
    }
  }
 console.log("Foruser",user.email)
  return (
    <>
      <div className="mainContainerSignup mainContainerLogin">
        <div className="cardSignup">
          <div className="cardSignupInner">
            <div className="topSignupTxt">
             Enter your email here to reset your password.
            </div>

           

          

            <div className="topTxtInput">
              <div className="inputLbl">Email</div>
              <input placeholder="ElonMusk@gmail.com" className="inputSignup" 
                 onChange={(e)=>handleChange(e)}
                 name="email"
              
              />
            </div>
          
            {valid===2&&<div style={{color:"red"}}>Enter email</div>}

            

            <div className="home-content--btn2"
                          onClick={loginClicked}

            >
              <div className="hover-btn2"   ></div>
              <button className="content-btn--inner2"
                // onClick={loginClicked}
              >
                <p>Submit</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
