import React from "react";
import "./WhtISCryp.css";
import curveImg from "./../../images/curve.png";
import whtCrypImg from "./../../images/whtCrypImg.png";
import separatorRight from "./../../images/separatorRight.png";
import imgHeading from "./../../images/headingWhtCrypto.png";
import imgHeading1 from "./../../images/whtCrypImg1.png";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
const WhtISCryp = () => {

  const navigate=useNavigate();
  const registerFoundClicked=()=>{
    
navigate('/user/join/crypto')
window.scrollTo(0, 0);

  }
  return (
    <>
      <div className="mainContainerWhtIsCryp"
       id="applyHere"
       >
        <div className="leftWhtCryp" id="idTopHeading">
          <div className="whtCrypHeading">What is The Crypto Hub?</div>
          <div className="topHeadingBottomWht">

          <img src={imgHeading}/>
          </div>
          <div className="whtIsCryMainTxt" >
          The Crypto Hub (TCH) platform focusses on connecting vetted & curated early-stage start-ups with investors and providing them with resources and tools to enrich their experience of fundraising and investing in the world of Cryptos, Blockchain & the Web3 ecosystem.
          </div>
        </div>
        <div id="applyHere1"></div>
        <div className="rightWhtCrypto" >
          <div className="topCardRightWht" >
       
              
              <div className="bottomImgRight" >
                <img src={imgHeading1} />
              </div>
            
            <div >
              <div className="rightTxtHeadingWht" >Investors</div>
              <div className="rightTxtParaWht" >
              Please register to be part of our select investors for early access to curated deal flows.
              </div>

           
        <div className="btnWhtIsCrypto"
       id="applyHere2"
       onClick={registerFoundClicked}
       >Register</div>
    
            </div>
          </div>
          <div ></div>

    
          <div className="topCardRightWht">
       
             
              <div className="bottomImgRight">
                <img src={whtCrypImg} />
              </div>
      

            <div>
              <div className="rightTxtHeadingWht">Founders</div>
              <div className="rightTxtParaWht">
              Please apply for your project to be curated among our featured start-ups.
              </div>

            <div className="btnWhtIsCrypto"
                onClick={registerFoundClicked}
            >Apply</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WhtISCryp;
