import React, { useState } from 'react'
import './VerificationCode.css'
import { verifyCode } from '../../redux/CustomerReducer';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
const VerificationCode = () => {

    const[code,setCode]=useState();
    const[verify,setVerify]=useState();
    const navigate=useNavigate();

const dispatch=useDispatch();
const submitCodeClicked=async()=>{
    if(code)
    {
        const data={
            code:code
        }
        
      const responseApi=await  dispatch(verifyCode(data))

      console.log("responseApi",responseApi?.payload?.data?.status)
      console.log("responseApi",responseApi?.payload?.data?.message)
      if(responseApi?.payload?.data?.status)
      {
        Swal.fire(
            '',
            responseApi?.payload?.data?.message,
            'success'
          )
        navigate('/user/login')
      }
    }
    else{
        setVerify(1)
    }
    
}
  return (
    <>
     <div className='mainVerifyCode'>
     <div className="verificationMain">
        <div className='verifyTxt'>Verify Your Email</div>
              <div className="inputLbl">Enter Code</div>
              <input placeholder="Enter your code here" className="inputSignup inputSignupCode" 
              
              onChange={(e)=>setCode(e.target.value)}
              name="fullName"
              />
            {verify&&  <p style={{color:"red"}}>Please enter code</p>}
            </div>
      <div className="verificationMain">
             <div className='codeBtn'
             
             onClick={submitCodeClicked}
             >Submit Code</div>
            </div>

     </div>


    </>
  )
}

export default VerificationCode