import React from "react";
import "./AdminHeader.css";
import { MdCircleNotifications } from "react-icons/md";
import { BsFillPersonFill } from "react-icons/bs";

const AdminHeader = (props) => {
  return (
    <>
      <div className={props.rightHeader? "topMainHeaderAdmin":"topMainHeaderAdminRight"}>
        <div className="mainHeaderAdmin">
          <div className="topNotificationNum">
            <span className="numberTxtNotifi">3</span>
            <MdCircleNotifications className="notificionIcon" />
          </div>

          <div className="topIconNameHd">

            <BsFillPersonFill className="bsProfileIconHd"/>
            <div>Billie Campos</div>

          </div>
        </div>
      </div>
    </>
  );
};

export default AdminHeader;
