import React, { useState } from "react";
import {
  FaTh,
  FaBars,
  FaUserAlt,
  FaRegChartBar,
  FaCommentAlt,
  FaShoppingBag,
  FaThList,
} from "react-icons/fa";
import { AiFillCar } from "react-icons/ai";
import { BsFillPeopleFill } from "react-icons/bs";
import { GoThreeBars } from "react-icons/go";
import Cookies from "universal-cookie";
import { FaLuggageCart } from "react-icons/fa";
import { IoIosPeople } from "react-icons/io";
import { AiOutlineLogout } from "react-icons/ai";
import { ImUsers } from "react-icons/im";
import { RiUserStarFill } from "react-icons/ri";
import { TbBrandBooking } from "react-icons/tb";
import { HiCurrencyPound } from "react-icons/hi";
import { NavLink } from "react-router-dom";

import "./SideBar.css";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
const SideBarAdmin = (props) => {
  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);
  const cookies = new Cookies();
  const menuItem = [
    {
      path: "/admin/users",
      name: "Users",
      icon: <ImUsers />,
    },
    {
      path: "/admin/ambassadors",
      name: "Ambassadors Request",
      icon: <RiUserStarFill />,
    },
    {
      path: "/admin/amb/verified",
      name: "Ambassadors Verified",
      icon: <RiUserStarFill />,
    },
    {
      path: "/admin/investors",
      name: "Investors",
      icon: <RiUserStarFill />,
    },
 
    {
      path: "/admin/founders",
      name: "Founders",
      icon: <RiUserStarFill />,
    },
 
    {
      path: "/admin/others",
      name: "Others",
      icon: <RiUserStarFill />,
    },
 
    {
      path: "/admin/parntners",
      name: "Partners",
      icon: <RiUserStarFill />,
    },
 
  
    // {
    //     path:"/product",
    //     name:"Product",
    //     icon:<FaShoppingBag/>
    // },
    // {
    //     path:"/productList",
    //     name:"Product List",
    //     icon:<FaThList/>
    // }
    
  ];

  const sideIconClicked = () => {
    setIsOpen(!isOpen);
    props.setRightHeader(!props.rightHeader);
  };

  const logoutClicked=()=>{
    cookies.remove('token', { path: '/' })

  }
  return (
    <>
      <div className="containerSideBar">
        <div style={{ width: isOpen ? "300px" : "50px" }} className="sidebar">
          <div className="top_section">
            <h1 style={{ display: isOpen ? "block" : "none" }} className="logo">
              Logo
            </h1>
            {isOpen === false && <div className="divToLevel"></div>}
            <div
              style={{ marginLeft: isOpen ? "50px" : "0px" }}
              className="bars"
            >
              <FaBars onClick={sideIconClicked} />
            </div>
          </div>
          {menuItem.map((item, index) => (
            <div>
              <NavLink
                to={item.path}
                key={index}
                className="link"
                activeclassName="active"
              >
                <div className="icon">{item.icon}</div>
                <div
                  style={{ display: isOpen ? "block" : "none" }}
                  className="link_text"
                >
                  {item.name}
                </div>
              </NavLink>
            </div>
          ))}
            <div>
                                  
                                  <NavLink
                                    to="/admin/login"
                                    // key={index}
                                    className="link"
                                    activeclassName="active"
                                    onClick={logoutClicked}
                                  >
                                    <div className="icon"><AiOutlineLogout/></div>
                                    <div
                                      style={{ display: isOpen ? "block" : "none" }}
                                      className="link_text"
                                    >
                                    Logout
                                    </div>
                                  </NavLink>
                                </div>
        </div>
      </div>

      <div className="offCanvasMobile">
        {[false].map((expand) => (
          <Navbar
            key={expand}
            bg="light"
            placement="end"
            expand={expand}
            className="mb-3"
          >
            <Container fluid>
              {/* <Navbar.Brand href="#">Navbar Offcanvas</Navbar.Brand> */}
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="start"
                className="offCanvasAdminSidebar"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Logo
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                    <div className="containerSideBarOffCan">
                      <div
                        style={{ width: isOpen ? "403px" : "50px" }}
                        className="sidebar"
                      >
                       
                        {menuItem.map((item, index) => (
                          <div>
                            <NavLink
                              to={item.path}
                              key={index}
                              className="link"
                              activeclassName="active"
                            >
                              <div className="icon">{item.icon}</div>
                              <div
                                style={{ display: isOpen ? "block" : "none" }}
                                className="link_text"
                              >
                                {item.name}
                              </div>
                            </NavLink>
                          </div>
                        ))}
                               
                      </div>
             
                    </div>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
      </div>
    </>
  );
};

export default SideBarAdmin;
