import React from 'react'
import './TopBar.css'
import logo from './../../images/logo-crypto.png'
import { Link } from 'react-router-dom'
const TopBar = () => {
  return (
    <>
    
    <div className='topBarMain'>
      <Link to="/">
        <img
        src={logo}
        className='topBarMainImg'
        />
        </Link>

    </div>
    </>
  )
}

export default TopBar