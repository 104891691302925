import React from 'react'
import LoginAdmin from '../Components/Admin/LoginAdmin/LoginAdmin'
import TopBar from '../Components/TopBar/TopBar'

const LoginAdminPage = () => {
  return (
    <>
    <TopBar/>
    <LoginAdmin/>
    </>
  )
}

export default LoginAdminPage